.inline-group {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 992px) {
  .inline-group {
    align-items: center;
    flex-direction: row;
  }
}
