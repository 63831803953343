.Input {
  width: 100%;
  background: #fff;
  color: #353535;
  font: inherit;
  /* box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1); */
  border: 0.1px solid rgba(115, 115, 115, 0.3);;
  outline: 0;
  /* border-radius: 5px; */
  padding: 14px 10px;
  margin: 1%;
}

.Input::placeholder {
  color: #a3a3a3;
}

.Input__error {
  border: 0.3px solid #9f3a38;
  background: #fff6f6;
}
.Input__error::placeholder {
  color: #9f3a38;
}

@media only screen and (max-width: 764px) {
  .Input {
    width: 100%;
  }
}
