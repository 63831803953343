.welcome {
  min-height: calc(100vh - 52px);
  width: 100%;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.welcome h1 {
  --font-size: 2rem;
  color: white;
  margin: 0;
  margin-bottom: 1.5rem;
  font-size: var(--font-size);
  line-height: var(--font-size*1.4);
}

.welcome__highlighted {
  color: var(--brand-yellow);
}

.welcome__content {
  max-width: 60%;
  padding: 0 var(--limited-content--padding);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 3rem;
  box-sizing: border-box;
}

.welcome__icon {
  color: white;
  font-size: 1rem;
}

.welcome__icon img {
  filter: grayscale(100%) brightness(120%);
}

.welcome__cta {
  margin-top: 2rem;
}

.welcome__slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

/*Animacion del fondo*/
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.welcome__slider:nth-of-type(1) {
  animation: fade 20s infinite;
  -webkit-animation: fade 20s infinite;
}

.welcome__slider:nth-of-type(2) {
  animation: fade2 20s infinite;
  -webkit-animation: fade2 20s infinite;
}

.welcome__slider:nth-of-type(3) {
  animation: fade3 20s infinite;
  -webkit-animation: fade3 20s infinite;
}

@media screen and (min-width: 992px) {
  .welcome h1 {
    --font-size: 3.5rem;
  }
}

@media screen and (max-width: 992px) {
  .welcome__slider {
    background-position: right top;
    filter: brightness(0.7);
  }
  .welcome__content {
    max-width: 90%;
  }
}
