.menu {
}

.menu--open {
}

.menu--open nav {
  transform: translateX(0vw);
}

.menu img {
  height: 32px;
  width: 32px;
}

nav {
  width: 80vw;
  height: 100vh;
  transform: translateX(80vw);
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 1rem;
  box-sizing: border-box;
  position: fixed;
  left: 20vw;
  top: 0;
  z-index: 1000;
  transition: transform 0.5s;
}

nav .menu__toggler {
  text-align: right;
}

nav a {
  text-decoration: none;
  padding: 1rem 0;
  color: white;
  font-weight: bold;
  transition: color 0.3s;
  cursor: pointer;
  font-size: 1em;
}

.menu__extra-links {
  margin: 1rem 0;
}

.menu__extra-links button {
  margin-bottom: 0.5rem;
  width: 100%;
}

.menu__extra-links a {
  padding: 0;;
}

.menu__extra-links button + button {
  margin-top: 0.5rem;
}

.menu__toggler {
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
}

.menu__languages span {
  color: white;
}

.menu__languages--selected {
  color: var(brand-yellow);
}

@media screen and (min-width: 992px) {
  .menu__toggler {
    display: none;
  }

  nav {
    display: flex;
    align-items: center;
    width: unset;
    height: unset;
    transform: unset;
    flex-direction: row;
    background-color: unset;
    padding: 1rem;
    box-sizing: border-box;
    position: unset;
  }

  nav.menu--dark a {
    color: #444;
  }

  nav a {
    padding: 1rem;
  }

  nav a:hover {
    color: #db9600;
  }

  .menu__extra-links,
  .menu__languages {
    display: none;
  }
}