.terms {
  padding: 1rem var(--limited-content--padding);
}

.terms__content {
  padding: 1rem var(--limited-content--padding);
}

.terms h1 {
  font-weight: 600;
  letter-spacing: 0.06em;
  font-size: 1.5em;
}

.terms p {
  font-weight: 100;
  letter-spacing: 0.06em;
  font-size: 0.95em;
  margin-bottom: 3em;
}

.terms__foot {
  margin-top: 4em;
}
