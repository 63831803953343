.selectable-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectable-item__icon {
  padding: 0.5rem;
}

.selectable-item__icon span {
  font-size: 1rem;
  display: none;
  white-space: normal !important;
}

.selectable-item--lightblue.selectable-item--selected {
  background-color: var(--brand-lightblue);
}

.selectable-item--yellow.selectable-item--selected {
  background-color: var(--brand-yellow);
}

.selectable-item--blue.selectable-item--selected {
  background-color: var(--brand-blue);
}

.selectable-item--selected .selectable-item__icon--blue span {
  color: var(--brand-blue);
  font-weight: 600;
}

.selectable-item--selected .selectable-item__icon--green span {
  color: var(--brand-green);
  font-weight: 600;
}

.selectable-item--selected .selectable-item__icon--yellow span {
  color: var(--brand-yellow);
  font-weight: 600;
}

@media screen and (min-width: 992px) {
  .selectable-item__icon img {
    display: none;
  }

  .selectable-item__icon span {
    display: block;
  }

  .selectable-item--lightblue.selectable-item--selected,
  .selectable-item--yellow.selectable-item--selected,
  .selectable-item--blue.selectable-item--selected {
    background-color: transparent;
  }
}

@media screen and (min-width: 1400px) {
  .selectable-item__icon span,
  .selectable-item--selected .selectable-item__icon--blue span {
    font-size: 1.3rem;
  }
}