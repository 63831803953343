.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.header--product h3 {
  color: var(--brand-blue);
} 

.header--benefits h3 {
  color: var(--brand-yellow);
} 

.header--features h3 {
  color: var(--brand-lightblue);
} 

.header--company {
  color: #fff;
} 
.header h3 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: center;
}


.header p {
  font-size: 1.2rem;
  text-align: left;
}

@media screen and (min-width: 992px) {
  .header {
    margin-left: 20%;
    margin-bottom: 4rem;
  }

  .header p {
    margin: 0;
    max-width: 600px;
    /* margin-top: 0.5rem; */
  }

  .header h3 {
    width: 70%;
  }
}

@media screen and (min-width: 1400px) {
  .header h3 {
    width: 70%;
    font-size: 3.5rem;
  }
  .header p {
    font-size: 1.5rem;
  }
}
