.header {
  min-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  padding: clamp(20px, 4vw, 60px);
}

.header__bottom {
  margin-top: 2rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #FFF;
  margin-bottom: 120px;
}

.header__bottom__left h1{
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-size: 1.5em;
  margin-bottom: 2rem;
}

.header__bottom__right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__bottom__right img {
  margin-right: -20px;
}

.header__bottom__right__old {
  font-weight: bold;
  color: var(--brand-blue);
  /* text-decoration: line-through; */

}

.header__bottom__right__new {
  font-size: 3em;
  font-weight: 100;
  margin-bottom: 1.5rem;
}

.header__bottom__right__new span {
  font-weight: 800;
}

.content {
  padding: clamp(20px, 4vw, 60px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 0 4em;
  background-color: #FFFFFF;
  width: 80%;
  margin: 0 auto;
  border-radius: 30px;
  transform: translateY(-120px);
  position: relative;
  padding-top: 100px;
}

.content__main {
  background-color: #D7E9F9;
}

.content__main img {
  position: absolute;
  left: 40px;
  top: -70px;
}

.content__item__title {
  font-size: 1.3em;
  font-weight: 600;
}

.content__item {
  margin-bottom: 2rem;
}

.content__item ul {
  list-style: none;
  padding-left: 0;
}

.content__item ul li {
  margin-bottom: 0.75em;
  font-weight: 100;
  color: #585858;
}

@media only screen and (max-width: 900px) {
  .content {
    padding-top: 140px;
    width: 95%;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}