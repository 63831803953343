.footer {
  padding: 2rem var(--limited-content--padding);
  display: flex;
  justify-content: center;
}

.footer--yellow {
  background-color: var(--brand-yellow);
}

.footer--blue {
  background-color: var(--brand-blue);
}

.footer__extra-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8em;
}

.footer__extra-links a {
  padding: 0.25rem;
}

.footer__extra-links p {
  color: white;
  margin: 0;
}

.footer__extra-links__extras a {
  color: #fff;
  font-weight: 600;
}

.footer__extra-links__extras_icon {
  color: #fff;
  font-weight: 600;
  margin-bottom: 1rem;
  display: none;
}

.footer__extra-links__extras_icon img {
  filter: brightness(0) invert(1);
}

@media screen and (min-width: 992px) {
  .footer__extra-links__extras_icon {
    display: none !important;
  }
}



