.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem var(--limited-content--padding);
  width: 100%;
}

.navbar__special {
  display: none;
  align-items: center;
}

.navbar__special button + a {
  margin-left: 1rem;
}

.navbar--white {
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0.5rem var(--limited-content--padding);
}

.navbar__fixed {
  position: fixed;
  opacity: 0;
  z-index: 1000;
  pointer-events: none;
  align-items: center;
  transition: opacity 0.3s;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0.5rem var(--limited-content--padding);

}

.navbar__fixed--showing {
  opacity: 1;
  pointer-events: all;
}

@media screen and (min-width: 992px) {
  .navbar__special {
    display: flex;
  }
}

