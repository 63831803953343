:root {
  --limited-content--padding: 20px;
  --brand-yellow: #db9600;
  --brand-blue: #185d9a;
  --brand-lightblue: #53AADD;
  --brand-green: #56919f;
}

#root {
  overflow-x: hidden;
}

@media screen and (min-width: 992px) {
  :root {
    --limited-content--padding: 60px;
  }
}

/* @media only screen and (min-width: 1400px) {
  :root {
    --limited-content--padding: 120px;
  }
} */

@font-face {
  font-family: 'Jakarta';
  src: url('assets/fonts/jakarta/PlusJakartaDisplay-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Jakarta';
  src: url('assets/fonts/jakarta/PlusJakartaDisplay-Light.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Jakarta';
  src: url('assets/fonts/jakarta/PlusJakartaDisplay-Medium.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Jakarta';
  src: url('assets/fonts/jakarta/PlusJakartaDisplay-Bold.ttf');
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Jakarta, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  padding: 5rem var(--limited-content--padding);
}
