.tabs-container {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 48rem) {
  .tabs-container {
    flex-direction: row;
  }
}
