.button {
  background-color: var(--brand-yellow);
  padding: 0.75rem 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.button span {
  color: white;
  text-decoration: none;
  letter-spacing: 0.03em;
}

/* .linkButton {
  padding: 0.75rem 0.5rem;
} */

