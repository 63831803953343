.tab-content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tab-content-header__icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.tab-content-header__icon img {
  display: none;
  box-sizing: content-box;
  margin-bottom: 0.5rem;
}

.tab-content-header--blue img {
  background-color: var(--brand-blue);
}

.tab-content-header--yellow img {
  background-color: var(--brand-yellow);
}

.tab-content-header--lightblue img {
  background-color: var(--brand-lightblue);
}

.tab-content-header__icon span {
  margin-left: 0 !important;
  white-space: normal !important;
  font-size: 1.25rem;
  font-weight: bold;
  /* max-width: 80%; */
}

@media screen and (min-width: 992px) {
  .tab-content-header__icon span {
    margin-left: 0.25rem;
  }

  .tab-content-header__icon img {
    display: block;
  }
}

@media screen and (min-width: 1400px) {
  .tab-content-header__icon span {
    font-size: 1.4rem;
  }
  .tab-content-header__icon img {
    width: 40px !important;
    height: 40px !important;
  }
}