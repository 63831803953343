.topbar {
  width: 100%;
  background-color: black;
  padding: 0.5rem var(--limited-content--padding);
  justify-content: space-between;
}

.topbar .item {
  color: white;
  transition: color 0.3s;
}

.topbar .item:hover {
  color: #db9600;
}

.topbarIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.topbarIconContainer .item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.invisible {
  display: none;
}

@media screen and (min-width: 992px) {
  .topbar {
    display: flex;
  }

  .invisible {
    display: flex;
  }

  
}
