:root {
  --tabs-header-item--padding: 0.5rem;
}

.tab-item-header {
  cursor: pointer;
  position: relative;
  padding: var(--tabs-header-item--padding);
  box-sizing: border-box;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.tab-item-header::before {
  content: '';
  width: calc(100% - (var(--tabs-header-item--padding) * 2));
  border: 2px solid transparent;
  position: absolute;
  display: block;
  left: -(var(--tabs-header-item--padding));
  bottom: -0.25rem;
  transition: border-color 0.3s;
}

.tab-item-header--selected::before {
  border-color: var(--tabs-header-item--selected-color);
}

@media screen and (min-width: 992px) {
  .tab-item-header {
    background-color: transparent;
    width: unset;
    display: block;
  }

  .tab-item-header::before {
    width: unset;
    height: calc(100% - (var(--tabs-header-item--padding) * 2));
    top: var(--tabs-header-item--padding);
    bottom: unset;
    left: -0.25rem;
  }
}

@media screen and (min-width: 1400px) {
  .tab-item-header {
    padding: calc(var(--tabs-header-item--padding) * 2);
  }
}

