.benefits {
  --tabs-header-item--selected-color: var(--brand-yellow);
}

.back {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}

.benefits > div {
  padding: 2rem 0;
}

@media screen and (min-width: 992px) {
  .benefits > div {
    padding: 5px 0;
  }
}
