.contact__wrapper {
  background-size: cover;
  padding: clamp(20px, 4vw, 60px);
  min-height: clamp(50vh, 80vh, 100vh);
}

.contact__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.contact__column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.content__title {
  text-align: center;
}

.content__title_head {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.content__title_sub {
  font-size: 1em;
  font-weight: 300;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.content {
  padding: clamp(20px, 4vw, 60px);
  display: block;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 0 4em;
  background-color: #ffffff;
  width: 100%;
  margin: 0 auto;
  border-radius: 30px;
  position: relative;
  padding-top: 60px;
}

.container__btn {
  padding-top: 10px;
  margin: 1%;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.btn {
  order: 1;
  width: 30%;
}

.terms {
  font-size: 12px;
  font-weight: 300;
  padding-left: 10px;
  order: 2;
  width: 70%;
}

.success {
  color: green;
  margin: 1%;
}

.error {
  color: #9f3a38;
  margin: 1%;
}
.contact__logo_column {
  text-align: center;
}

.contact__logo_column .icon {
  color: #fff;
  transition: color 0.3s;
  font-size: 1.5em;
  font-weight: 700;
}

@media only screen and (max-width: 900px) {
  .content {
    /* padding-top: 140px; */
    width: 95%;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }

  .contact__logo_column {
    display: none;
  }

  .contact__column {
    flex: auto;
  }

  .btn {
    width: 100%;
  }

  .terms {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
  }
}
