.tabs-header {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: 100%;
}

@media screen and (min-width: 48rem) {
  .tabs-header {
    width: 300px;
    flex-direction: column;
  }
}

@media screen and (min-width: 1400px) {
  .tabs-header {
    width: 400px;
    flex-direction: column;
    margin-right: 2rem;
  }
}

/* @media screen and (min-width: 64rem) {
  .tabs-header {
    flex-direction: column;
  }
} */
