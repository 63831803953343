.company-benefit {
  padding: 1rem;
  color: white;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.company-benefit p {
  letter-spacing: 0.03em;
} 

.company-benefit + .company-benefit {
  margin-top: 1.5rem;
}

.company-benefit__icon {
  margin-right: 1.5rem;
}
