.dropdown {
  position: relative;
  z-index: 999;
}

.dropdown__items {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  position: absolute;
  top: 100%;
  width: 100%;
}

.dropdown__chevron {
  fill: white;
}

.dropdown__selected-item {
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}

.dropdown__items--open {
  opacity: 1;
  pointer-events: all;
}
