.tab-content {
  background-color: white;
}

.tab-content p {
  white-space: pre-line;
}

.tab-content__content {
  padding: 2rem 3rem 2rem 5rem;
}

.tab-content__image {
  width: 350px;
  height: 100%;
  flex-shrink: 0;
  display: none;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tab-content__image img {
  position: absolute;
  right: -100px;
  min-width: 100%;
  width: 500px;
}

.tab-content__content {
  clip-path: polygon(6% 0%, 100% 0, 100% 100%, 0% 100%);
  color: #fff;
}

.tab-content__content--blue {
  background-color: var(--brand-blue);
}

.tab-content__content--yellow {
  background-color: var(--brand-yellow);
}

.tab-content__content--lightblue {
  background-color: var(--brand-lightblue);
}

@media screen and (min-width: 992px) {
  .tab-content {
    padding-left: 2rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
  }

  .tab-content__image {
    display: flex;
  }

  .tab-content__content {
    min-height: 400px;
  }
}

@media screen and (max-width: 768px) {
  .tab-content__content {
    clip-path: none;
  }
}

@media screen and (min-width: 1400px) {
  .tab-content__content {
    padding: 6rem 3rem 6rem 5rem;
    min-height: 600px;
  }
  .tab-content p {
    font-size: 1.4rem;
  }
}