.language-dropdown {
}

.language-dropdown__icon {
  color: white;
}

.language-dropdown__icon--selected span {
  color: var(--brand-yellow);
}

.language-dropdown__item--no-dropdown {
  padding: 0.5rem 0;
  font-size: 1rem;
}
