.button {
  background-color: #FFF;
  padding: 0.75rem 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  cursor: pointer;
  color: var(--brand-blue);
}

.button a {
  color: var(--brand-blue);
  text-decoration: none;
  letter-spacing: 0.03em;
}

.linkButton {
  padding: 0.75rem 0.5rem;
}