.icon {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
}

.icon + .icon {
  margin-top: 1rem;
}

.icon .label {
  margin-left: 0.5rem;
  letter-spacing: 0.03em;
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  .icon + .icon {
    margin-top: unset;
    margin-left: 3rem;
  }
}
