.button {
  background-color: var(--brand-blue);;
  padding: 0.75rem 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.button a {
  color: white;
  text-decoration: none;
  letter-spacing: 0.03em;
}
