.product {
  --tabs-header-item--selected-color: var(--brand-blue);
}

.back {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.product > div {
  padding: 2rem 0;
}

@media screen and (min-width: 992px) {
  .product > div {
    padding: 5px 0;
  }
}
