.dropdown-item {
  cursor: pointer;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  color: white;
  background-color: black;
}

.dropdown-item:not(.dropdown-item--selected):hover {
  color: var(--brand-yellow);
  background-color: #666;
}
